import request from '@/utils/request'
//所属行业
export function dict_industry(){
    return request.post('dict/industry')
}
//经营期限类型
export function dict_operating_time_limit_type(){
    return request.post('dict/operating_time_limit_type')
}

//认证为企业用户
export function user_authentication(data){
    return request.post('user/authentication',data)
}

//企业列表
export function enterprise_index(data){
    return request.post('Enterprise/index',data)
}

export function enterprise_detail(data){
    return request.post('Enterprise/detail',data)
}

// 优惠券列表
export function coupon_coupon_view(data){
    return request.post('Coupon/coupon_view',data)
}

//更多优惠券列表
export function coupon_coupon_details(data){
    return request.post('Coupon/coupon_details',data)
}

//新闻分类
export function news_type(data){
    return request.post('news/type',data)
}
//底部列表
export function footer_list(data){
    return request.post('news/getFootNews',data)
}
// 新闻列表
export function news_index(data){
    return request.post('news/index',data)
}

//新闻详情
export function news_lists(data){
    return request.post('news/lists',data)
}

//企业查产品和优惠券
export function enterprise_query(data){
    return request.post('Enterprise/query',data)
}
//产品列表
export function get_product_detail(data){
    return request.post('Enterprise/product_detail',data)
}

// 首页搜索
export function index_search(data){
    return request.post('index/search',data)
}

//产品详情
export function enterprise_details(data){
    return request.post('Enterprise/details',data)
}
// 产品详情2
export function enterprise_details2(data){
    return request.post('Enterprise/detaila',data)
}

//轮播图
export function index_carousel(data){
    return request.post('index/carousel',data)
}
//退出
export function user_logout(data){
    return request.post('user/logout',data)
}

//查询已认证的企业信息
export function user_enterprise_detail(data){
    return request.post('user/enterprise_detail',data)
}

//修改企业认证信息
export function user_enterprise_revise(data){
    return request.post('user/enterprise_revise',data)
}

//修改企业认证信息
export function Enterprise_couponindex(data){
    return request.post('Enterprise/couponindex',data)
}

//找回密码
export function user_resetpwd(data){
    return request.post('user/resetpwd',data)
}

