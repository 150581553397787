<template>
  <div class="center_top">
    <div class="title">
      {{ title }}
    </div>
    <ul>
      <li v-for="(item, index) in navList" :key="index" :class="actionIndex == index ? 'action' : ''"
         @click="getIndustry(item.value,index)"><a href="javascript:;">{{ item.label
        }}</a></li>
      <li>
        <el-dropdown @command="handleCommand" v-if="navList.length>0">
          <span class="el-dropdown-link" >
            更多分类<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in navList2" :key="index" :command="item.value">{{ item.label }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name:'TopNavigation',
  props:['title','ClassificationList',''],
  data(){
    return{
      actionIndex: 0,
      navList:[],
      navList2:[]
    }
  },
  mounted(){
    this.actionIndex=0
  },
  methods:{
    getIndustry(value,index){
      console.log(value,index)
      this.actionIndex = index
      this.$emit('getCouponCouponDetails',value,1)
    },
    handleCommand(command) {
      this.actionIndex=-1
      this.$emit('getCouponCouponDetails',command,1)
    }
  },
  watch:{
    ClassificationList(newValue,oldValue){
      let arr = []
      let arr2 = []
      newValue.forEach((item, index) => {
        if (index <= 4) {
          arr.push(item)
        } else {
          arr2.push(item)
        }
      })
      this.navList = arr
      this.navList.unshift({
            "value": 0,
            "label": "全部"
        },)
      this.navList2 = arr2
    }
  }
}
</script>

<style lang="less" scoped>
.center_top {
  display: flex;
  width: 100%;
  height: 50px;
  margin-bottom: 36px;

  .title {
    width: 160px;
    height: 50px;
    background-color: #b40003;
    font-size: 26px;
    color: white;
    text-align: center;
    line-height: 50px;
    margin-right: 15px;
  }

  ul {
    flex: 1;
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    align-items: center;
    // margin: 0 31px;
    line-height: 50px;
    font-size: 16px;

    li {
      box-sizing: border-box;
      color: #b6b6b6;
      cursor: pointer;
      padding: 0 15px;
      a,
      .el-dropdown-link {
        text-decoration: none;
        color: #b6b6b6;
        font-size: 16px;
      }

    }

    .action {
      background-color: #ecf5ff;

      a {
        color: #6bb6ff;
      }
    }

  }
}
</style>