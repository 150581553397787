<template>
    <div class="application_enterprise">
        <div class="application_enterprise-container">
            <div class="container-center">
                <div class="form">
                    <el-form ref="form" :model="form" label-width="140px">
                        <div class="form-container">
                            <div class="left">
                                <div class="left-content">
                                    <el-form-item label="营业执照照片">
                                        <div class="upload-area" @click="inputFile2" @mouseenter="handleMouseEnter2"
                                            @mouseleave="handleMouseLeave2">
                                            <input type="file" style="display: none;" ref="inputFile2"
                                                @change="handlefile2">
                                            <img :src="form.business_license ? form.business_license : imgSrc" alt="">
                                            <!-- <i class="el-icon-delete-solid" v-if="iconshow2"></i> -->
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="身份证照片">
                                        <div class="upload-area" @click="inputFile1" @mouseenter="handleMouseEnter1"
                                            @mouseleave="handleMouseLeave1">
                                            <input type="file" style="display: none;" ref="inputFile1"
                                                @change="handlefile1">
                                            <img :src="form.id_card ? form.id_card : imgSrc" alt="">
                                            <!-- <i class="el-icon-delete-solid" v-if="iconshow1"></i> -->
                                        </div>
                                    </el-form-item>
                                </div>

                                <el-form-item label="企业社会信用代码">
                                    <el-input v-model="form.credit_code" placeholder="请输入企业社会信用代码"></el-input>
                                </el-form-item>
                                <el-form-item label="法人姓名">
                                    <el-input v-model="form.operator_name" placeholder="请输入法人姓名"></el-input>
                                </el-form-item>
                                <el-form-item label="企业所在省">
                                    <el-select v-model="form.province" placeholder="请选择企业所在省" style="width: 100%;">
                                        <el-option :label="item.label" :value="item.value" v-for="item in provinceArr"
                                            :key="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="企业所在市">
                                    <el-select v-model="form.city" placeholder="请选择企业所在市" style="width: 100%;">
                                        <el-option :label="item.label" :value="item.value" v-for="item in cityArr"
                                            :key="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="企业所在区">
                                    <el-select v-model="form.area" placeholder="请选择企业所在区" style="width: 100%;">
                                        <el-option :label="item.label" :value="item.value" v-for="item in areaArr"
                                            :key="item.value"></el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="企业所属行业">
                                    <el-select v-model="form.industry" placeholder="请选择企业所属行业" style="width: 100%;">
                                        <el-option :label="item.label" :value="item.value" v-for="item in industry"
                                            :key="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="营业执照开始时间">
                                    <el-date-picker type="date" placeholder="请选择营业执照开始时间" value-format="yyyy-MM-dd"
                                        v-model="form.operating_time_limit_date_begin"
                                        style="width: 100%;"></el-date-picker>
                                </el-form-item>

                            </div>
                            <div class="right">

                                <el-form-item label="企业照片" style="margin-left: 80px;">
                                    <div class="upload-area" @click="inputFile3" @mouseenter="handleMouseEnter3"
                                        @mouseleave="handleMouseLeave3">
                                        <input type="file" style="display: none;" ref="inputFile3" @change="handlefile3">
                                        <img :src="form.enterprise_img ? form.enterprise_img : imgSrc" alt="">
                                        <!-- <i class="el-icon-delete-solid" v-if="iconshow3"></i> -->
                                    </div>
                                </el-form-item>
                                <el-form-item label="企业名称">
                                    <el-input v-model="form.enterprise_name" placeholder="请输入企业名称"></el-input>
                                </el-form-item>
                                <el-form-item label="法人身份证号">
                                    <el-input v-model="form.operator_id_card_no" placeholder="请输入法人身份证号"></el-input>
                                </el-form-item>
                                <el-form-item label="营业执照所在地址">
                                    <el-input v-model="form.address" placeholder="请输入营业执照所在地址"></el-input>
                                </el-form-item>
                                <el-form-item label="工商登记号">
                                    <el-input v-model="form.business_registration" placeholder="请输入工商登记号"></el-input>
                                </el-form-item>
                                <el-form-item label="注册资本">
                                    <el-input v-model="form.registered_capital" placeholder="请输入注册资本"></el-input>
                                </el-form-item>
                                <!-- <el-form-item label="企业介绍">
                                    <el-input v-model="form.introduce" placeholder="请输入企业介绍"></el-input>
                                </el-form-item>
                                <el-form-item label="经营范围">
                                    <el-input v-model="form.business_scope" placeholder="请输入经营范围"></el-input>
                                </el-form-item> -->
                                <el-form-item label="经营期限类型">
                                    <el-select v-model="form.operating_time_limit_type" placeholder="请选择经营期限类型"
                                        style="width: 100%;">
                                        <el-option :label="item.label" :value="item.value"
                                            v-for="item in operating_time_limit_type_Arr" :key="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="营业执照结束时间">
                                    <el-date-picker type="date" placeholder="请选择营业执照结束时间"
                                        v-model="form.operating_time_limit_date_end" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                                </el-form-item>

                            </div>
                        </div>
                        <el-form-item label="企业介绍">
                            <el-input type="textarea" v-model="form.introduce"></el-input>
                        </el-form-item>
                        <el-form-item label="经营范围">
                            <el-input type="textarea" v-model="form.business_scope"></el-input>
                        </el-form-item>
                        <el-form-item label="企业资质证书">
                            <el-upload action="http://cqyp.yzs.so/api/coupon/upload" list-type="picture-card"
                                :on-preview="handlePictureCardPreview" :on-remove="handleRemove" multiple
                                accept=".jpg,.jpeg,.png,.gif" :on-success="handleSuccess" :file-list="this.certificateArr">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-form>
                    <div class="btn-item">
                        <el-button type="primary" @click="onSubmit">确认修改</el-button>
                        <!-- <el-button @click="handleReturn">取消</el-button> -->
                    </div>


                </div>
            </div>
        </div>
        <!-- <myFooter></myFooter> -->
    </div>
</template>
<script>
import { updataFile, area_economize, area_market, area_distinguish } from '@/api/index'
import { dict_industry, dict_operating_time_limit_type, user_authentication, user_enterprise_detail, user_enterprise_revise } from '@/api/api'
export default {
    name: 'setCompanyInfo',
    data() {
        return {
            form: {
                id_card: '',//身份证照片
                business_license: '',//营业执照照片
                credit_code: '',//企业社会信用代码
                enterprise_name: '',//企业名称
                operator_name: '',//法人姓名
                operator_id_card_no: '',//法人身份证号码
                province: '',//所在省
                city: '',//所在市
                area: '',//所在区
                industry: '',//所属行业
                registered_capital: '',//注册资本
                business_scope: '',//经营范围
                operating_time_limit_type: '',//经营期限类型
                operating_time_limit_date_begin: '',//业执照开始时间
                operating_time_limit_date_end: '',//业执照结束时间
                address: '',//营业执照所在地址
                enterprise_img: '',//企业照片
                certificate: '',//企业证书
                business_registration: "",//工商登记号
                introduce: '',//企业介绍
            },
            provinceArr: [],//省
            cityArr: [],//市
            areaArr: [],//区
            industry: [],//所属行业
            operating_time_limit_type_Arr: [],//经营期限类型
            iconshow1: false,//身份证照片删除按钮显示状态
            iconshow2: false,//营业执照照片删除按钮显示状态
            iconshow3: false,//企业照片
            imgSrc: 'http://cqyp.yzs.so/uploads/20230810/02d96030bdccf1cbc44ebc4e18735476.png',

            dialogImageUrl: '',
            dialogVisible: false,
            certificateArr: []
        }
    },
    mounted() {
        this.handleData()
        // area_economize().then(res => {
        //     this.provinceArr = res.data
        // })
        // dict_industry().then(res => {
        //     this.industry = res.data
        // })
        // dict_operating_time_limit_type().then(res => {
        //     this.operating_time_limit_type_Arr = res.data
        // })
    },
    watch: {
        'form.province': {
            immediate: true,//初始化时让hander调用一下
            handler(newValue, oldValue) {
                area_market({
                    id: newValue
                }).then(res => {
                    this.cityArr = res.data
                })
            }
        },
        'form.city': {
            immediate: true,//初始化时让hander调用一下
            handler(newValue, oldValue) {
                area_distinguish({
                    id: newValue
                }).then(res => {
                    this.areaArr = res.data
                })
            }
        }
    },
    methods: {
        async handleData() {
            try {
                let res1 = await area_economize()
                this.provinceArr = res1.data
                let res2 = await dict_industry()
                this.industry = res2.data
                let res3 = await dict_operating_time_limit_type()
                this.operating_time_limit_type_Arr = res3.data
                let res4 = await user_enterprise_detail()
                console.log(res4)
                let arr = []
                res4.data.certificate.forEach(item => {
                    arr.push(item.certificate_path)
                })
                this.form = {
                    ...res4.data,
                    certificate: arr.join(',')
                }
                res4.data.certificate.forEach(item => {
                    this.certificateArr.push({
                        url: item.certificate_path
                    })
                })
                console.log(this.form)
            } catch (error) {

            }
            // this.certificateArr=res4.data.certificate
        },
        handleSuccess(response, file, fileList) {
            // console.log(response, file, fileList)
            let that = this
            if (response.code == 0) {
                this.$message({
                    message: `${response.msg}，请重新上传`,
                    type: 'warning'
                });
                try {
                    fileList.forEach((item, index) => {
                        if (item.response.code == 0) {
                            fileList.splice(index, 1)
                        }
                    })
                } catch (error) {

                }

            } else {
                this.$message({
                    message: `${response.msg}`,
                    type: 'success'
                });
                let arr = []
                try {
                    fileList.forEach((item, index) => {
                        if (item.response) {
                            arr.push(item.response.code.fullurl)
                        } else {
                            arr.push(item.url)
                        }
                    })
                } catch (error) {
                    console.log(2, error)
                }

                this.form.certificate = arr.join(',')


            }
            // console.log("123", response, file, fileList)
            // console.log('form',this.form)

        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            let arr = []
            fileList.forEach(item => {
                if (item.response) {
                    arr.push(item.response.code.fullurl)
                } else {
                    arr.push(item.url)
                }
            })
            this.form.certificate = arr.join(',')
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        // 营业执照照片
        handlefile3(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            updataFile(formData).then(res => {
                // console.log(res)
                if (res.code == 0) {
                    this.$message({
                        message: `${res.msg}，请重新上传`,
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    this.form.enterprise_img = res.code.fullurl
                }
            })
        },
        // 营业执照照片删除移入
        handleMouseEnter3() {
            if (this.form.enterprise_img) {
                this.iconshow3 = true
            }
        },
        // 营业执照照片删除移出
        handleMouseLeave3() {
            if (this.form.enterprise_img) {
                this.iconshow3 = false
            }
        },
        // 获取营业执照照片
        inputFile3() {
            this.$refs.inputFile3.click()
        },
        // 营业执照照片
        handlefile2(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            updataFile(formData).then(res => {
                // console.log(res)
                if (res.code == 0) {
                    this.$message({
                        message: `${res.msg}，请重新上传`,
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    this.form.business_license = res.code.fullurl
                }

            })
        },
        // 营业执照照片删除移入
        handleMouseEnter2() {
            if (this.form.business_license) {
                this.iconshow2 = true
            }
        },
        // 营业执照照片删除移出
        handleMouseLeave2() {
            if (this.form.business_license) {
                this.iconshow2 = false
            }
        },
        // 获取营业执照照片
        inputFile2() {
            this.$refs.inputFile2.click()
        },
        // 获取身份证照片
        inputFile1() {
            this.$refs.inputFile1.click()
        },
        // 身份证照片删除移入
        handleMouseEnter1() {
            if (this.form.id_card) {
                this.iconshow1 = true
            }
        },
        // 身份证照片删除移出
        handleMouseLeave1() {
            if (this.form.id_card) {
                this.iconshow1 = false
            }
        },
        // 身份证照片
        handlefile1(event) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            updataFile(formData).then(res => {
                // console.log(res)
                if (res.code == 0) {
                    this.$message({
                        message: `${res.msg}，请重新上传`,
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    this.form.id_card = res.code.fullurl
                }
            })
        },
        onSubmit() {
            // console.log('submit!');
            // console.log(this.form)

            user_enterprise_revise(this.form).then(res => {
                console.log(res)
                if (res.code == 0) {
                    this.$message({
                        showClose: true,
                        message: `${res.msg}`,
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        showClose: true,
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    let timer = setTimeout(() => {
                        window.location.reload();
                        clearTimeout(timer)
                    }, 500)
                }
            }).catch(err => {
                console.log(err)
                // this.$message({
                //     showClose: true,
                //     message: `${err.response.data.msg}`,
                //     type: 'error'
                // });
            })
        },
        handleReturn() {
            this.$router.push({
                path: '/user',
            });
        },
    }
}
</script>
<style lang="less" scoped>
.application_enterprise {
    width: 100%;

    .el-form {
        width: 100% !important;
    }

    .application_enterprise-container {
        width: 100%;
        // height: calc(100vh - 190px);
        display: flex;
        justify-content: center;

        .container-center {
            width: 1200px;

            h3 {
                font-size: 20px;
                font-weight: 900;
                color: #b40003;
                margin-top: 30px;
            }

            .form {
                width: 100%;
                padding: 15px 20px;
                box-sizing: border-box;
                margin-top: 20px;

                .form-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .line {
                        text-align: center;
                    }

                    .left {
                        width: 48%;

                        .left-content {
                            display: flex;
                            justify-content: space-between;
                        }

                        .upload-area {
                            width: 150px;
                            height: 150px;
                            box-sizing: border-box;
                            border: 1px solid #eee;
                            position: relative;

                            // margin-left: 30px;
                            img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                border: 0;
                                left: 0;
                            }

                            i {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 18px;
                            }
                        }

                        ::v-deep .el-form-item__label {
                            // width: auto !important;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        :v-deep .el-form-item__content {
                            margin-left: 120px !important;
                        }

                    }

                    .right {
                        width: 48%;

                        .upload-area {
                            width: 150px;
                            height: 150px;
                            box-sizing: border-box;
                            border: 1px solid #eee;
                            position: relative;

                            // margin-left: 20px;
                            img {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                border: 0;
                                left: 0;
                            }

                            i {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 18px;
                            }
                        }

                        ::v-deep .el-form-item__label {
                            // width: auto !important;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        :v-deep .el-form-item__content {
                            margin-left: 120px !important;
                        }
                    }


                }

                ::v-deep .el-form-item__label {
                    // width: auto !important;
                    font-size: 16px;
                    font-weight: 600;
                }

                ::v-deep .el-textarea__inner {
                    height: 200px !important;
                }

                .btn-item {
                    // margin-top: 15px;
                    display: flex;
                    justify-content: center;

                    ::v-deep .el-button {
                        font-size: 15px;
                    }

                    ::v-deep .el-button:nth-of-type(1) {
                        background-color: #b40003;
                        border: 0;
                    }
                }
            }
        }
    }
}
</style>