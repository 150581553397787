export const constRoute = [
  {//根路由
    path: '/',
    redirect: '/home'
  },
  {//首页
    path: '/home',
    name: 'home',
    component: () => import('@/pages/home/index'),
  },
  {//登录
    path: '/login',
    name: 'login_index',
    component: () => import('@/pages/login/login_index'),
  },
  {//注册
    path: '/register',
    name: 'regist_index',
    component: () => import('@/pages/register/regist_index'),
  },
  {//优惠券
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/pages/coupons/index'),
  },
  {
    path: '/coupons_detail',
    name: 'coupons_detail',
    component: () => import('@/pages/coupons/detail')
  },
  {//产品列表
    path: '/product',
    name: 'product',
    component: () => import('@/pages/product/index'),
  },
  {//产品列表
    path: '/searchSort',
    name: 'searchSort',
    component: () => import('@/pages/product/searchSort'),
  },
  {//产品详情
    path: '/product_detail',
    name: 'product_detail',
    component: () => import('@/pages/product/product_detail/index'),
  },
  {//企业列表
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/pages/enterprise/index'),
  },
  {//企业详情
    path: '/enterprise_detail',
    name: 'enterprise_detail',
    component: () => import('@/pages/enterprise/enterprise_detail/index'),
  },
  {//新闻通知
    path: '/news',
    name: 'news',
    component: () => import('@/pages/news/index.vue'),
  },
  {//新闻通知
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('@/pages/news/newsdetalis.vue'),
  },
  {//用户详情
    path: '/user',
    name: 'user_index',
    meta: { isAuth: true },
    component: () => import('@/pages/user/user_index.vue'),
  },
  {//申请为企业用户
    path: '/user/applicationEnterprise',
    name: 'application_enterprise',
    meta: { isAuth: true },
    component: () => import('@/pages/user/application_enterprise')
  },
  {//购物常见问题
    path: '/shoppingGuide/questions',
    name:'questions',
    component: () => import('@/pages/shoppingGuide/questions.vue')
  },
  {//购物流程
    path: '/shoppingGuide/shoppingProcess',
    name: 'shoppingProcess',
    component: () => import('@/pages/shoppingGuide/shoppingProcess.vue')
  },
  {//底部列表详情
    path: '/footerListDetalis',
    name: 'shoppingProcess',
    component: () => import('@/pages/footerListDetalis/footerListDetalis.vue')
  }
]