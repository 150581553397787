import axios from 'axios'
import Vue from 'vue';
import router from '../router'

Vue.prototype.$axios = axios
//创建axios实例
let ajax = axios.create({
  // baseURL: 'http://cqyp.yzs.so/api/',
  baseURL:'http://192.168.1.15/api/',
  timeout: 10000
})

// axios.defaults.headers.post['Token'] = localStorage.getItem('token')? localStorage.getItem('token') : '';
// let that=this
//请求拦截器
ajax.interceptors.request.use(
  config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // const token = ;   
        if (localStorage.getItem('token')) {
          config.headers.Token = localStorage.getItem('token')
        }     
        // config.headers.Authorization['Token']  = localStorage.getItem('token')      
        return config;    
    },    
    error => {        
        return Promise.error(error);    
    })
//响应拦截器
ajax.interceptors.response.use(response => {
  if (response.status === 200) {
    return Promise.resolve(response.data);
  } else {
    return Promise.reject(response.data);
  }
}, (error) => {
  if (error.response.status) {
    switch (error.response.status) {          
      case 401:
        Vue.prototype.$message({
          type: 'warning',
          message: '请登录后操作'
        });
        let timer =setTimeout(()=>{
          router.push({
            path: '/login',
          })
        },1000)
        break;               
      case 403:
        Vue.prototype.$message({
          type: 'warning',
          message: '请重新登录'
        });
        localStorage.clear()
        setTimeout(() => {
          router.push({
            path: '/login',
          })
        }, 1000);
        break;
      case 404:
        Vue.prototype.$message({
          type: 'warning',
          message: '网络错误'
        });
        break;
      default:
        break
    }
    return Promise.reject(error.response);
  }
});
export default ajax;