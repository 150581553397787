import myFooter from './myFooter/index'                      
import myHeader from './myHeader/index.vue'          
import mySearch from './other/search.vue'   
import TopNavigation from './TopNavigation/index.vue'                                   
import setCompanyInfo from './setInfo/setCompanyInfo.vue'                                   
export default {
    install(Vue) {                       
      Vue.component('myFooter', myFooter)
      Vue.component('myHeader', myHeader)
      Vue.component('mySearch', mySearch)
      Vue.component('TopNavigation', TopNavigation)
      Vue.component('setCompanyInfo', setCompanyInfo)
    }
}