import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)


const state = {
    // 搜索状态
    searchstate: false,
    // 分类父级
    selsectarr: [],
    // 服务器地址
    // baseURL:'http://192.168.1.15'
    baseURL:'http://cqyp.nmgmxj.com'
}

const actions = {
    // 搜索状态
    searchstate(content, value) {
        content.commit('SEARCHSTATE', value)
    },
    selsectarr(content, value) {
        content.commit('SELSECTARR', value)
    },
}

const mutations = {
    // 搜索状态
    SEARCHSTATE(state, value) {
        state.searchstate = value
    },
    SELSECTARR(state, value) {
        state.selsectarr = value
    }
}

const getters = {

}

export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    plugins: [
        createPersistedState({
            storage: window.localStorage
        })
    ]
})