<template>
  <div>
    <div class="header">
      <section class="section-center">
        <div class="left">
          <p>欢迎来到诚企优品！</p>
        </div>
        <div class="right">
          <div class="login-item" v-if="!isShow">
            您好，请
            <span @click="login">登录</span>
            <span @click="register">免费注册</span>
            <span @click="addToFavorites">收藏夹</span>
            <span @click="noData">客户服务</span>
          </div>
          <div class="login-item-login" v-else>
            <img :src="userinfo.avatar">
            <p @click="PersonalCenter">{{ userinfo.username }}</p>
            <span>/</span>
            <p @click="Edit">退出</p>
          </div>
        </div>
      </section>
    </div>
    <div class="search">
      <section class="search-center">
        <div class="item">
          <img src="../../assets/logo2.png" alt="诚企优选" class="logo" @click="handlehome">

          <div class="btn-item">
            <p class="" @click="goIndex">首页</p>
            <p @click="handleEnterprise">企业</p>
            <p @click="product">产品</p>
            <p @click="coupon">优惠券</p>
            <!-- <p @click="news">新闻</p> -->
          </div>

          <div class="search-input">
            <div class="select">
              <el-select v-model="selectValue" placeholder="请选择" @change="handleChange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="input">
              <input type="text" v-model="inputValue" :placeholder="placeholder" @keyup.enter="handleSearch">
            </div>
            <span class="vline"></span>
            <!-- <img src="../../assets/搜索.png" alt="搜索" @click="handleSearch"> -->
            <span class="iconfont icon-search" @click="handleSearch"></span>
          </div>

          <!-- <img src="../../assets/二维码.png" alt="扫描二维码" class="code"> -->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { user_logout, index_search } from '@/api/api'
export default {
  name: 'myHeader',
  data() {
    return {
      isShow: false,
      selectValue: 'product',
      inputValue: '',
      placeholder: '搜索企业/产品',
      options: [{
        value: 'product',
        label: '产品'
      }, {
        value: 'enterprise',
        label: '企业'
      }]
    }
  },
  computed: {
    // isShow() {
    //   return localStorage.getItem('token')
    // },
    userinfo() {
      return JSON.parse(localStorage.getItem('userinfo'))
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.isShow = true
    } else {
      this.isShow = false
    }
  },

  methods: {
    noData(){
      this.$message({
        type:'warning',
        message:"功能未上线，敬请期待"
      })
    },
    news() {
      this.$router.push({
        path: '/news'
      })
    },
    goIndex() {
      this.$router.push({
        path: '/'
      })
    },
    product() {
      this.$router.push({
        path: '/product'
      })
    },
    coupon() {
      this.$router.push({
        path: '/coupons'
      })
    },
    login() {
      this.$router.push({
        path: '/login'
      })
    },
    register() {
      this.$router.push({
        path: '/register'
      })
    },
    PersonalCenter() {
      this.$router.push({
        path: '/user'
      })
    },
    Edit() {
      user_logout({
        token: localStorage.getItem('token')
      }).then(res => {
        console.log(res)
        if (res.code) {
          this.$message({
            message: `${res.msg}`,
            type: 'success'
          });
          localStorage.clear()
          this.isShow = false
          let timer = setTimeout(() => {
            this.$router.push({
              path: '/home'
            })
            clearTimeout(timer)
          }, 1000);
        }
      }).catch(err => {
        console.log(err)

      })
      // alert('退出登录')
    },
    handleEnterprise() {
      this.$router.push({
        path: '/enterprise'
      })
    },
    // 搜索
    handleSearch() {
      if (this.inputValue != '') {
        // let searchCategory = async ()=> {
        //   let res = await index_search({
        //     name: this.inputValue,
        //     enterprise_name: this.inputValue
        //   })
        //   console.log('-------------------------')
        //   console.log(res)
        //   if(res.total == 0) {
        //     this.selectValue = 'product'
        //   }else if (res.total == 1) {
        //     this.selectValue = 'enterprise'
        //   }
        //   return res
        // }
        // searchCategory()
        if (this.selectValue == 'product') {
          this.$store.dispatch('searchstate', true)

          this.$router.push({
            path: '/product',
            query: {
              selectValue: this.selectValue,
              inputValue: this.inputValue,
            },
          });
          this.inputValue = ''

        } else if (this.selectValue == 'enterprise') {
          this.$store.dispatch('searchstate', true)

          this.$router.push({
            path: '/enterprise',
            query: {
              selectValue: this.selectValue,
              inputValue: this.inputValue,
            },
          });
          this.inputValue = ''
        }
      } else {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        });
      }

    },
    handlehome() {
      this.$router.push({
        path: '/home',
      });
    },
    handleChange(item) {
      // console.log(item)
      switch (item) {
        case 'product':
          this.placeholder = '搜索产品名称'
          break
        case 'enterprise':
          this.placeholder = '搜索企业名称'
          break
        default:
          break
      }
    },
    open3() {
      this.$message({
        message: '浏览器限制，请使用快捷键ctrl + D添加',
        type: 'warning'
      });
    },
    addToFavorites() {
      // 创建一个新的收藏夹项
      const url = window.location;
      const title = document.title;
      const newFavorite = {
        url: url,
        title: title,
      };

      // 这里可以添加额外的逻辑，比如检查用户是否已登录等

      // 将收藏夹项添加到浏览器的收藏夹
      // this.addToBrowserFavoritesGeneric(newFavorite);
      try {
        window.external.addFavorite(url, title);          //ie
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, "");     //firefox
        } catch (e) {
          this.open3()//chrome opera safari
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
@lightGray: #9c9c9c;
@loginColor: #f57435;

.header {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 5px #ccc;

  .section-center {
    width: 1200px;
    min-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      p {
        font-size: 16px;
        color: #343434;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @Demo: {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 50px;
        font-size: 16px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 5px;
        }

        span {
          cursor: pointer;
          margin: 0 15px;

          &:nth-of-type(1) {
            color: @loginColor;
          }

          &:nth-of-type(2) {
            color: @loginColor;
          }
        }
      }

      .login-item-login {
        cursor: pointer;
        @Demo()
      }

      .login-item {
        @Demo();

        color: @lightGray;

        img {
          width: 0px;
          height: 0px;
        }

        p {
          cursor: pointer;


          &:nth-of-type(1) {
            width: 38px;
          }
        }
      }
    }
  }
}

.search {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  // box-shadow: 0px 2px 5px #ccc;

  .search-center {
    width: 1200px;
    min-width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 160px;
        // height: 85%;
        cursor: pointer;
      }

      .btn-item {
        height: 100%;
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: 16px;
        flex: 1;

        .action {
          color: red;
        }

        p {
          cursor: pointer;
          margin-right: 42px;
        }
      }

      .search-input {
        width: 430px;
        height: 40px;
        // background-color: #eee;
        border-radius: 20px;
        position: relative;
        display: flex;

        .select {
          width: 30%;
          height: 100%;

          ::v-deep .el-range-editor.is-active,
          .el-range-editor.is-active:hover,
          ::v-deep .el-select .el-input.is-focus .el-input__inner {
            // background-color: transparent;
            border: 1px solid #eee;
            // border-radius: 20px;
          }

          ::v-deep .el-select .el-input__inner:focus {
            border: 1px solid #eee;
          }

          ::v-deep .el-select .el-input__inner {
            border: 1px solid #eee;
          }
        }

        .input {
          width: 70%;
          height: 100%;
          position: relative;

          input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            margin: 0;
            padding: 5px 24px;
            padding-left: 12px;
            border: none;
            background: none;
            font: inherit;
            color: inherit;
            text-decoration: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            outline: none;
            position: absolute;
            left: 0;
            border: 1px solid #eee;
          }
        }


        .vline {
          height: 55%;
          width: 1px;
          background-color: @lightGray;
          position: absolute;
          right: 40px;
          top: 50%;
          transform: translateY(-50%);
        }

        .icon-search {
          width: 24px;
          height: 24px;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          font-size: 24px;
          color: @lightGray;
        }
      }
    }
  }
}
</style>