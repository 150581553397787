import request from '@/utils/request'
import ajax from '@/utils/ajax'
//产品分类
export function dict_type(){
    return request.post('dict/type')
}
//产品分类父类
export function dict_father_type(){
    return request.post('dict/father_type')
}
//特殊分类
export function dict_positionCategory(){
    return request.post('dict/positionCategory')
}
//产品分类子类
export function dict_son_type(data){
    return request.post('dict/son_type',data)
}

//获取优惠券分类
export function getCouponsClassify(){
    return request.post('dict/tiyanjuan')
}

//获取地区级联
export function getAddress(){
    return request.post('area/all')
}

//发布优惠券
export function Coupon_coupon_add(data){
    return request.post('Coupon/coupon_add',data)
}

//上传文件
export function updataFile(data){
    return request.post('coupon/upload',data)
}

// 省
export function area_economize(data){
    return request.post('area/economize',data)
}
//市
export function area_market(data){
    return request.post('area/market',data)
}
//区
export function area_distinguish(data){
    return request.post('area/distinguish',data)
}

//用户登录
export function user_login(data){
    return request.post('user/login',{
        'account':data.account,
        'password':data.password
    })
}

// 获取验证码
export function user_code(){
    return request.post('user/login')
}

// 用户注册
export function user_register(data){
    return request.post('user/register',{
        'username':data.username,
        'password':data.password,
        'email':data.email,
        'mobile':data.mobile,
        'code':data.code
    })
}

// 获取用户发布的优惠券列表
export function user_codeList(data){
    return request.post('Coupon/coupon_my',{
        'user_id':data.userId,
        'type':data.type
    })
}

// 删除优惠券
export function delete_userCoupons(data){
    return request.post('Coupon/coupon_del',{
        'id':data
    })
}

// 发布商品
export function enterprise_add(data){
    return request.post('Enterprise/add',{
        'id':data
    })
}

// 获取产品类别
export function enterprise_classF(){
    return request.post('dict/product')
}

// 领取优惠券
export function coupon_receive(data){
    return request.post('Coupon/coupon_receive',{
        'coupon_id':data.coupon_id,
        'user_id':data.user_id
    })
}

// 个人用户优惠券列表
export function coupon_List(data){
    return request.post('Coupon/coupon_receivemy',{
        'user_id':data.user_id,
        'status':data.status
    })
}

// 企业用户已上架商品列表
export function commodity_list(data){
    return request.post('Enterprise/person',{
        'user_id':data
    })
}

// 企业用户删除指定的已上架商品
export function commodity_del(id){
    return request.post('Enterprise/del',{
        'id':id
    })
}

// 企业用户中心的企业信息
export function enterprise_info(){
    return request.post('user/enterprise_detail')
}


// 企业用户的核销列表
export function verification_List(userid){
    return request.post('Coupon/writedetails',{
        'user_id':userid
    })
}

// 企业用户进行核销
export function goVerification(data){
    return request.post('Coupon/write',{
        'id':data.verification_code,
        'coupon_user':data.user_id
    })
}

// 获取手机验证码
export function goPhoneCode(data){
    return request.post('user/get_captcha',{
        'mobile':data.mobile,
        'event':data.event
    })
}

// 修改产品信息
export function setComInfo(data){
    return request.post('Enterprise/edit',{
        'info':data.info
    })
}

// 验证码登录
export function login_m(data){
    return request.post('user/mobilelogin',{
        'mobile':data.mobile,
        'captcha':data.captcha
    })
}

// 用户修改密码
export function setUser_password(data){
    return request.post('user/resetpwd',{
        'mobile':data.mobile,
        'newpassword':data.newpassword,
        'captcha':data.captcha
    })
}

// 用户修改用户名
export function setUser_username(data){
    return request.post('user/mobilelogin',{
        'mobile':data.mobile,
        'captcha':data.captcha
    })
}


// 用户修改头像，用户名
export function setUser_avatar(data){
    return request.post('user/profile',{
        'username':data.username,
        'avatar':data.avatar
    })
}


// 获取用户信息
export function getUserInfo_new(){
    return request.post('user/index')
}

