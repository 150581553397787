<template>
  <div class="footer">
    <div class="footer-menu">
      <div class="footer-menu-left">
        <img
          src="../../assets/logo2.png"
          alt="诚企优选"
          class="logo"
          @click="handlehome"
        />
      </div>
      <div class="footer-menu-center">
        <ul class="menu-list" v-for="(item, index) in list" :key="index">
          <li class="menu-list-item">{{ item.name }}</li>
          <li
            class="menu-list-item"
            v-for="(value, index) in item.content"
            :key="index"
          >
            <router-link :to="value.href">{{ value.title }}</router-link>
          </li>
        </ul>
      </div>
      <div class="footer-menu-right"></div>
    </div>
    <div class="footer-bottom">
      <div class="text">
        蒙ICP备:17002566号-1 主办：内蒙古信用促进会
        地址：内蒙古自治区呼和浩特市赛罕区如意开发区晨光街2号呼和浩特科创中心
        电话：0471-6659368
      </div>
    </div>
  </div>
</template>

<script>
import { footer_list } from "../../api/api";
export default {
  name: "myFooter",
  data() {
    return {
      list: [
        // [
        //   {
        //     value: "购物指南",
        //   },
        //   {
        //     value: "购物常见问题",
        //     href: "/shoppingGuide/questions",
        //   },
        //   {
        //     value: "购物流程",
        //     href: "/shoppingGuide/shoppingProcess",
        //   },
        //   {
        //     value: "联系客服",
        //     href: "/shoppingGuide/customerService",
        //   },
        // ],
        // [
        //   {
        //     value: "关于我们",
        //   },
        //   {
        //     value: "平台介绍",
        //     href: "/aboutUs/platformIntroduction",
        //   },
        // ],
      ],
    };
  },
  methods: {
    handlehome() {
      this.$router.push({
        path: "/home",
      });
    },
    noData() {
      this.$message({
        type: "warning",
        message: "功能未上线，敬请期待",
      });
    },
    no() {},
  },
  mounted() {
    footer_list().then((res) => {
      //获取列表
      let list = res.data;
      let i = list.findIndex(item => item.name == '售后指南')
      list.splice(i,1)
      list.forEach((item) => {
        item.content.forEach((obj) => {
          obj.href = `/footerListDetalis?id=${obj.id}`
        });
        this.list.push(item)
      });
    });
  },
};
</script>

<style lang="less">
@mainBgc: #f8f8f8;

.footer {
  width: 100%;
  min-width: 1200px;
  background-color: @mainBgc;

  .footer-menu {
    width: 1200px;
    height: 180px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: center;

    .footer-menu-left {
      width: 375px;

      .logo {
        width: 226px;
        cursor: pointer;
      }
    }

    .footer-menu-center {
      height: 100%;
      display: flex;
      flex: 1;
      justify-content: start;
      font-size: 16px;

      .menu-list {
        height: 100%;
        margin-right: 100px;
        margin-top: 40px;

        .menu-list-item {
          margin-bottom: 12px;
          cursor: pointer;

          &:first-child {
            font-weight: 800;
            cursor: default;
          }
        }
      }
    }

    .footer-menu-right {
      width: 218px;
    }
  }

  .footer-bottom {
    background-color: #fff;

    .text {
      width: 1200px;
      margin: 0 auto;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 17px;
      color: #000;
    }
  }
}
</style>
