<template>
  <div class="search">
    <section class="search-center">
      <div class="item">
        <img src="../../assets/logo2.png" alt="诚企优选" class="logo" @click="handlehome">
        <div class="search-input">
          <div class="select">
            <el-select v-model="selectValue" placeholder="请选择" @change="handleChange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="input">
            <input type="text" v-model="inputValue" :placeholder="placeholder" @keyup.enter="handleSearch">
          </div>
          <span></span>
          <img src="../../assets/搜索.png" alt="搜索" @click="handleSearch">
        </div>

        <!-- <img src="../../assets/二维码.png" alt="扫描二维码" class="code"> -->
      </div>
    </section>
  </div>
</template>
  
<script>

export default {
  name: 'search',
  data() {
    return {
      selectValue: 'product',
      inputValue: '',
      placeholder: '搜索产品名称',
      options: [{
        value: 'product',
        label: '产品'
      }, {
        value: 'enterprise',
        label: '企业'
      }],
    }
  },
  methods: {
    // 搜索
    handleSearch() {
      if (this.inputValue != '') {

        if (this.selectValue == 'product') {
          this.$store.dispatch('searchstate', true)

          this.$router.push({
            path: '/product',
            query: {
              selectValue: this.selectValue,
              inputValue: this.inputValue,
            },
          });
          this.inputValue = ''

        } else if (this.selectValue == 'enterprise') {
          this.$store.dispatch('searchstate', true)
          
          this.$router.push({
            path: '/enterprise',
            query: {
              selectValue: this.selectValue,
              inputValue: this.inputValue,
            },
          });
          this.inputValue = ''
        }
      } else {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        });
      }

    },
    handlehome() {
      this.$router.push({
        path: '/home',
      });
    },
    handleChange(item) {
      // console.log(item)
      switch (item) {
        case 'product':
          this.placeholder = '搜索产品名称'
          break
        case 'enterprise':
          this.placeholder = '搜索企业名称'
          break
        default:
          break
      }
    },
  }
}
</script>
  
<style lang="less" scoped>  
  .search {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 5px #ccc;

    .search-center {
      width: 1200px;
      min-width: 1200px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .item {
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
          width: 160px;
          // height: 85%;
          cursor: pointer;
        }

        .search-input {
          width: 430px;
          height: 40px;
          background-color: #eee;
          border-radius: 20px;
          position: relative;
          display: flex;

          .select {
            width: 30%;
            height: 100%;

            ::v-deep .el-range-editor.is-active,
            .el-range-editor.is-active:hover,
            ::v-deep .el-select .el-input.is-focus .el-input__inner {
              // background-color: transparent;
              border: 1px solid #eee;
              // border-radius: 20px;
            }

            ::v-deep .el-select .el-input__inner:focus {
              border: 1px solid #eee;
            }

            ::v-deep .el-select .el-input__inner {
              border: 1px solid #eee;
            }
          }

          .input {
            width: 70%;
            height: 100%;
            position: relative;

            input {
              width: 92%;
              height: 100%;
              box-sizing: border-box;
              margin: 0;
              padding: 5px 24px;
              padding-left: 12px;
              border: none;
              background: none;
              font: inherit;
              color: inherit;
              text-decoration: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              outline: none;
              position: absolute;
              left: 0;
            }
          }


          span {
            height: 55%;
            width: 2px;
            background-color: #999;
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translateY(-50%);
          }

          img {
            width: 21px;
            height: 21px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
</style>