import Vue from 'vue'
import VueRouter from 'vue-router'
import {constRoute} from './constRoute'

let push = VueRouter.prototype.push;
let replace = VueRouter.prototype.replace;
//重写VueRouter.prototype原型对象的push|replace方法
VueRouter.prototype.push = function (location) {
    //调用push方法且篡改push方法内部this指向->vueRouter类的实例
    push.call(this, location, () => { }, () => { });
}
VueRouter.prototype.replace = function (location) {
    //调用push方法且篡改push方法内部this指向->vueRouter类的实例
    replace.apply(this, [location, () => {}, () => {}]);
}
Vue.use(VueRouter)

const router = new VueRouter({
  routes:constRoute,
  mode:'history'
})
router.beforeEach((to,from,next)=>{
  if(to.meta.isAuth){//判断是否要被鉴权     
      if(localStorage.getItem('token')){
          next()
      }else{
        // console.log(1)
        
        Vue.prototype.$message({
          type: 'warning',
          message: '请登录后操作'
        });
        setTimeout(() => {
          next({
            path: '/login'
         })
        }, 1000);
          
      }
  }else{
      next()
  }
})
export default router